*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}

.container{
  width: 100%;
  height: 100%;

}

.detailArea{
  display: flex;
  justify-content: center;
  background: #f6f6f2;
    
}

.detailLeft{
  width: 60%;
  margin: 0 auto;
  margin-top: 80px;
}

.detailLeft img{
  width: 100%;
}

.detailLeft .productDescription{
  margin: 20px 0;
  font-size: 25px;
  font-weight: 700;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 20px;
}

.detailLeft .productWrap{
width: 70%;
margin: 0 auto;

}

.detailLeft .productWrap .leftDetailWrap{
  border: 1px solid #ccc;
  padding: 20px
}

.detailRight{
  width: 40%;
  position: relative;
}

.detailRight .detailInfo{
  position: fixed;
  top:100px
}

.detailRight .detailInfo h2{
  margin-left: 20px
  
}


.detailRight .detailInfo .desDetail{
  text-align: left;
  
}

.detailRight .detailInfo .desDetail .subTitle{
  text-align: center;
  margin-bottom: 30px;
  color: gray;
}

.detailRight .detailInfo .desDetail .subTitle1 p:nth-child(1) {
  font-weight: 700;
  margin-bottom: 5px;  
}

.detailRight .detailInfo .desDetail .subTitle2 p:nth-child(1) {
  font-weight: 700;
  margin: 10px 0px 5px 0px;
}

.detailRight .detailInfo .desDetail .subTitle2 p:nth-child(7) {
  font-weight: 700;
  margin-top: 3px;
  margin-left: 20px
}

.detailRight .detailInfo .desDetail .subTitle3 p:nth-child(1) {
  font-weight: 700;
  margin: 20px 0;
  
}

.detailRight .detailInfo .desDetail .subTitle4 p {
  font-weight: 700; 
}

.detailRight .detailInfo .desDetail .subTitle5 p {
  margin-left: 20px;
  margin-bottom: 2px
}


.detailRight .detailInfo .productDescription{
  width: 100%;
  margin-left: 20px;
}

.detailRight .detailInfo .productDescription div{
  margin: 10px 0;
}

.detailRight .price{
  font-size: 20px;
  font-weight: 700;
}

.total{
  display: flex;
  justify-content: center;
  padding: 20px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin: 20px;
}

.total .totalName{
  width: 20%;
}

.total .totalName p{
  font-size: 20px;
  font-weight: 700;
}

.total .totalPrice{
  width: 70%;

}

.total .totalPrice p{
  font-size: 20px;
  font-weight: 700;
  text-align: right;
  
}

.purchaseButton, .cartButton{
  width: 43%;
  padding: 15px;
  margin: 10px;
  background-color: #000000;
  color: white;
  border:solid #aab89d;
  
  cursor: pointer;
  
}
.paymentButton button:first-child{
  background-color: #f5f5f5ff;
  color : black;
}

.paymentButton button:nth-child(2){
  background-color: #aab89d;
  
}

.selectProduct{
  display: flex;
  width: 100%;
  justify-content: center;
}

.selectProduct select{
  width: 73%;
  padding: 10px;
  border: 1px solid #ccc;
  
  margin-right: 5px;
  font-size: 15px;
  font-weight: 500;
}

.selectProduct button{
  width: 30%;
  padding: 10px;
  background-color: #000000;
  color: white;
  border: none;
  cursor: pointer;
  margin-left: 5px;
  
}

.selectProduct span{
  font-size: 18px;
  font-weight: 500;
  margin-left: 5px;
}
.selectedOption button{
  width : 15px;
  height : 15px;
}

.selectedOption li{
  font-size : 10px;
}
/* register css */
.regi_container{
  width: 33.33%; 
  min-width: 560px;   
  margin: 0 auto; 
  padding: 20px; 
  box-sizing: border-box; 
  border: 1px solid #ddd; 
  background-color: #f9f9f9; 
}

.productQuantity button{
  background-color: #aab89d ;
}

@media (max-width: 1279.98px) {
  .detailLeft{
    width: 45%;
  }
  .detailLeft .productWrap{
    margin-left: 40px;
    margin-right: 10px;
  }
  .detailRight{
    width: 55%
  }


  .detailInfo div p{
    font-size:16px;
  }
}

@media (max-width: 980px) {
  .detailArea{
    display: flex;
    flex-direction: column;
  }
  .detailLeft{
    order:2;
    width:100%;
    display: flex;
    justify-content: center;
  }
  .detailLeft .productWrap {
    width: 60%;
    margin:0
  }
  .detailRight{
    width: 100%
  }
  .detailRight .detailInfo{
    position:relative;
    order:1

  }

  .detailRight .detailInfo .desDetail{
    text-align:center
  }
  .paymentButton button{
    width: 40%;
    height: 15%;
    margin-bottom: 40px;
  }
  .detailInfo div p{
    font-size: 15px;
  }

}